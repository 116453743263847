import React, { useEffect, useState } from 'react'
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact'
import { useUserContext } from '../../context/users/UserContext'
import { validatePermissions } from '../../context/users/UserActions'
import { NavLink } from 'react-router-dom'
import {obtenerDominio} from "../../utils/utilsFunct"
const keyToken = process.env.REACT_APP_SESSION_KEY || 'alleanza_jwtoken'

export const getDomain = () => {
  var hostname = window.location.hostname.split('.')
  return hostname.slice(-2).join('.')
}

const Sidebar = () => {
  const { isAuthenticated, logOutUser, authenticatedUser } = useUserContext()
  const [isVisiable, setIsVisiable] = useState(true)

  useEffect(() => {
    setIsVisiable(!window.location.pathname.includes('/sesion/'))
  }, [])

  const handleSingOut = () => {
    localStorage.removeItem(keyToken)
    logOutUser({})
    window.location = '/'
  }

  function sendToCanvas(sessionCode) {
    localStorage.setItem('alleanza_currentSession', JSON.stringify({ sessionCode }))
    window.open(`${window.location.origin}/sesion/${sessionCode}`, "_blank")
  }

  function redirectToEncuesta() {
    var currentProtocol = window.location.protocol; // Obtener el protocolo actual
    var currentDomain = obtenerDominio(); // Obtener el dominio actual

    // Construir la URL de la encuesta
    var encuestaURL = currentProtocol + "//encuesta." + currentDomain + "/";

    // Redirigir a la URL de la encuesta
    console.log(encuestaURL);
    window.open(encuestaURL, "_blank");
  }

  function redirectToTutorial() {
    window.open('https://www.youtube.com/', '_blank')
  }

  return (
    <>
      {(isAuthenticated && !authenticatedUser.pendingPlan && isVisiable) && (<>
        <CDBSidebar textColor='#333' backgroundColor='#fff'>
          <CDBSidebarContent textColor='#333' className='sidebar-content'>
            <CDBSidebarMenu>

              {validatePermissions(authenticatedUser.permissions, 'perfil') ? (
                <NavLink to='/informacionPersonal' activeclassname='activeClicked'>
                  <CDBSidebarMenuItem icon='user-circle' className="icon">{authenticatedUser.fullName || 'Perfil'}</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'users') ? (
                <NavLink to='/usuarios' activeclassname='activeClicked'>
                  <CDBSidebarMenuItem icon='users' className="icon">Usuarios</CDBSidebarMenuItem>

                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'plans') ? (
                <NavLink to='/planes' activeclassname='activeClicked'>
                  <CDBSidebarMenuItem icon='table' className="icon">Planes</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'questions') ? (
                  <NavLink to='/preguntas' activeclassname='activeClicked'>
                    <CDBSidebarMenuItem icon='question' className="icon">Preguntas</CDBSidebarMenuItem>
                  </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'isAdmin'  ) ? (
                <NavLink to='/fotografias' activeclassname='activeClicked'>
                  <CDBSidebarMenuItem icon='image' className="icon">Fotografías</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'session') ? (
                <NavLink to='/sessions' activeclassname='activeClicked' data-bs-toggle='tooltip'  data-bs-placement='top'
                  title='Inicia aquí para crear un nuevo evento para tus invitados.'>
                  <CDBSidebarMenuItem icon='calendar-week' className="icon">Sesiones</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'canvas') && authenticatedUser.sessionCode ? (
                <NavLink onClick={() => sendToCanvas(authenticatedUser.sessionCode)} activeclassname='activeClicked'
                  data-bs-toggle='tooltip'  data-bs-placement='top' title='Accede a las preguntas y fotografías que quieres usar en tu sesión.'>
                  <CDBSidebarMenuItem icon='border-all' className="icon">Canvas</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {/* <NavLink onClick={() => redirectToEncuesta()} activeclassname='activeClicked' data-bs-toggle='tooltip'  data-bs-placement='top'
                  title='Comparte tu experiencia de uso como anfitrión.'>
                <CDBSidebarMenuItem icon='check-square' className="icon">Encuesta</CDBSidebarMenuItem>
              </NavLink> */}

              {validatePermissions(authenticatedUser.permissions,  'isHost') || validatePermissions(authenticatedUser.permissions, 'isAdmin') ? (
                <NavLink to='/reportes' activeclassname='activeClicked' data-bs-toggle='tooltip'  data-bs-placement='top'
                  title='Descarga aquí los resultados de tus sesiones.'>
                  <CDBSidebarMenuItem icon='file-excel' className="icon">Reportes</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions, 'membership') ? (
                <NavLink to='/Licencias' activeclassname='activeClicked' data-bs-toggle='tooltip'  data-bs-placement='top'
                  title='Adquiere más o reasigna tus licencias.'>
                  <CDBSidebarMenuItem icon='id-card' className="icon">Licencias</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              {validatePermissions(authenticatedUser.permissions,  'isHost') || validatePermissions(authenticatedUser.permissions, 'isAdmin') ? (
                <NavLink onClick={() => redirectToTutorial()} activeclassname='activeClicked' data-bs-toggle='tooltip'  data-bs-placement='top'
                    title='Video documentación sobre el uso de esta plataforma.'>
                  <CDBSidebarMenuItem icon='video' className="icon">Tutorial</CDBSidebarMenuItem>
                </NavLink>
              ) : (<></>)}

              <NavLink onClick={handleSingOut} activeclassname='activeClicked'>
                <CDBSidebarMenuItem icon='exclamation-circle' className="icon">Salir</CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarHeader prefix={<i className='fa fa-angle-left fa-large'></i>}>
          </CDBSidebarHeader>

        </CDBSidebar>
      </>)}
    </>
  )
}

export default Sidebar;
