import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import {getCategories, getPhotos, getPhotosByCategory} from "../../context/photos/PhotoActions";
import Select from 'react-select';
import { FaRedo } from "react-icons/fa";

const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_IMAGES_SERVICE || '/file/api/v1'}`

export default function AddPhotoToCanvas({ showModalPic, handleClose, setPhotosObjs,  saveQuestionToSession }) {

    const [photos, setPhotos] = useState([]);
    const [photosSelected, setPhotosSelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ value: 0 }); // Initialize with value 0
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const getCategoriesAndPhotos = async () => {

        const category = await getCategories();
        setCategories(category);
        setSelectedOption({ label: category[0].categoryName, value: category[0].id }); // Set default category
        const photosByCategory = await getPhotosByCategory( category[0].categoryName );
        setPhotos(photosByCategory);


    }

    useEffect(() => {
        getCategoriesAndPhotos();
    }, []);

    useEffect(() => {

        const getInfo = async () => {

            const photosByCategory = await getPhotosByCategory( selectedOption.label );
            setPhotos(photosByCategory);

            // Reset selectAllChecked when changing categories
            setSelectAllChecked(false);
        }

        getInfo();

    }, [selectedOption]);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption)
    };

    const handleSelectAllChange = () => {

        if (selectAllChecked) {
            // Remove selected photos for current category
            setPhotosSelected(prevState => {
                const updatedSelected = { ...prevState };
                delete updatedSelected[selectedOption.value];
                return updatedSelected;
            });
        }else {
            // Add selected photos for current category

            const allPhotoIds = Object.values(photos).map(photo => ({
                id: photo.id,
                image: photo.uri,
                fileName: photo.fileName
            }));

            setPhotosSelected(prevState => ({
                ...prevState,
                [selectedOption.value]: allPhotoIds
            }));
        }
        setSelectAllChecked(!selectAllChecked);
    };

    const handleDeleteAllChange = () => {
        setPhotosSelected([])
        setPhotosObjs([])
    };

    const handlePhotoSelect = (photoId, photoFileName, uri) => {
        // Obtiene los IDs de fotos seleccionadas para la opción actual
        const selectedPhotos = photosSelected[selectedOption.value] || [];

        // Verifica si el ID de la foto ya está seleccionado
        const isPhotoSelected = selectedPhotos.some(photo => photo.id === photoId);

        // Si la foto ya está seleccionada, la elimina del estado
        if (isPhotoSelected) {
            setPhotosSelected({
                ...photosSelected,
                [selectedOption.value]: selectedPhotos.filter(photo => photo.id !== photoId)
            });
        } else {
            // Si la foto no está seleccionada, la agrega al estado
            setPhotosSelected({
                ...photosSelected,
                [selectedOption.value]: [...selectedPhotos, { id: photoId, image : uri,  fileName: photoFileName }]
            });
        }
    };

    const opts = [
        ...categories.map((category) => ({
            label: category.categoryName,
            value: category.id,
        })),
    ];

    useEffect(() => {

        const combinedPhotos = Object.values(photosSelected).flatMap(photos =>
            photos.map(photo => ({
                imageId: photo.id,
                image: `${service}/downloadFile/${photo.fileName}`,
                name: photo.fileName
            }))
        );
        setPhotosObjs(combinedPhotos)
    }, [photosSelected]);

    const isPhotoSelected = (photoId) => {
        return Object.values(photosSelected).some(photos => photos.some(photo => photo.id === photoId));
    };

    return (
        <Modal show={showModalPic} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Imágenes Metafóricas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                    <div className='col-9'>
                        Selecciona fotos de varias categorías y, una vez finalizada la selección guárdalas haciendo click en el botón de Guardar Fotos.
                    </div>
                    <div className='col-3'>
                        <button className='btn btn-primary btn-block mb-3' onClick={() => {
                            saveQuestionToSession();
                            handleDeleteAllChange();
                        }}>
                            Guardar Fotos
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <Select
                            className='mt-2 w-full'
                            value={selectedOption}
                            onChange={handleSelectChange}
                            options={opts}
                            isSearchable={true}
                            placeholder='Buscar categoría'
                        />
                    </div>
                    <div className='col-md-auto d-flex align-items-center'>
                        <input
                            className='form-check-input mx-2 p-2 '
                            type='checkbox'
                            role='switch'
                            id='flexSwitchCheckDefault'
                            onChange={handleSelectAllChange}
                            checked={selectAllChecked}
                        />
                        <label className='form-check-label ml-2'>Seleccionar Todo</label>
                    </div>
                    <div className='col-md-auto d-flex align-items-center'>
                        <button
                            onClick={handleDeleteAllChange}
                            className='btn  bg-transparent mx-2'
                            data-bs-title='This top tooltip is themed via CSS variables.'
                        >
                            <FaRedo className='amount-icon text-warning' />
                        </button>
                        <label className='form-check-label ml-2'>Reiniciar selección</label>
                    </div>
                </div>
                <Form>
                    <div className='row text-center p-3'>
                        {photos.map((photo, index) => (
                            <div className={`card col-3 d-flex justify-content-center align-items-center ${isPhotoSelected(photo.id)  ? 'border-naranja' : ''} bg-transparent`} key={index}>
                                <div onClick={() => handlePhotoSelect(photo.id, photo.fileName, photo.uri)} className={`card col-4 text-bg-secondary bg-transparent`} key={photo.id} style={{ width: '8rem' }}>
                                    <div className='d-flex justify-content-center align-items-center' style={{ verticalAlign: 'middle', textAlign: 'center' }} >
                                        <img
                                            className='text-center d-flex justify-content-center'
                                            src={`${window.location.origin}/file/api/v1${photo.uri}`}
                                            alt='Loading...'
                                            width='150'
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary btn-block mb-3' onClick={() => {
                    saveQuestionToSession();
                    handleDeleteAllChange();
                }}>
                    Guardar Fotos
                </button>
            </Modal.Footer>
        </Modal>
    )
}
