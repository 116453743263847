import Modal from "react-bootstrap/Modal";
import  {useEffect, useState} from "react";
import { getQuestions} from "../../context/questions/QuestionActions";
import {useUserContext} from "../../context/users/UserContext";

export default function  AddQuestionToCanvas( {showModalQuestion,handleOnSelectQuestion, handleClose, onClickNext, onblurCreateQuestion, newQuestion, setNewQuestion } ) {

    const { authenticatedUser, setAlert } = useUserContext()
    const [questions, setQuestions] = useState([])
    const [selectQuestion, setSelectQuestion] = useState()

    const fetchData = async () => {
        const response = await getQuestions(
            { path: 'questions', token: authenticatedUser.value, sortBy: 'question', pageSize: 1000 })
        if (response.error) {
            setQuestions([])
        } else {
            setQuestions(response.data)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <Modal show={showModalQuestion} onHide={handleClose} className={'flex justify-content-center'} >
            <Modal.Header closeButton>
                <Modal.Title>Preguntas</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div>

                    <div className="form-check form-switch">
                        <input className="form-check-input toggle-btn" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={newQuestion} onClick={setNewQuestion}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Crear nueva pregunta</label>

                    </div>

                    {newQuestion &&
                        <div className={'m-1'}>
                            <label>Pregunta: *</label>
                            <input
                                type='text'
                                className='form-control mt-1'
                                placeholder='Escribe tu propia pregunta aquí'
                                onChange={(e) => onblurCreateQuestion( e)}
                            />
                        </div>
                    }
                    {!newQuestion &&
                        <div className={'m-1'} >
                            <label>Preguntas: *</label>
                            <select
                                className='form-select mt-1'
                                data-choices='data-choices'
                                data-options='{"removeItemButton":true,"placeholder":true}'
                                value={selectQuestion}
                                onChange={handleOnSelectQuestion}>
                                <option  defaultValue key='' value=''>Selecciona una opción</option>
                                {questions.map((question) => (
                                    <option key={question.questionId} value={question.questionId}>
                                        {question.question}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }

                </div>

            </Modal.Body>

            <Modal.Footer>
                <button onClick={onClickNext} className='btn btn-primary btn-block mb-3'>
                    Seleccionar Pregunta
                </button>
            </Modal.Footer>
        </Modal>
    )
}
