import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AuthenticatedRoute from './pages/authenticatedRoute'

import Home from './pages/home'
import Home2 from './pages/home2'
import NavBar from './components/layout/NavBar'
import Sidebar from './components/layout/Sidebar'
import AlertComponent from './components/layout/AlertComponent'

import Login from './pages/login'
import RestorePassword from './components/users/RestorePassword'

import AddUser from './components/users/AddUser'
import AddUserWithPlan from './components/users/AddUserWithPlan'
import AddHostUser from './components/users/AddHostUser'
import AddHostUserCode from './components/users/AddHostUserCode'
import Users from './components/users/Users'
import Photos from './components/catalogs/Photos'
import User from './components/users/User'
import UpdateOwnInfo from './components/users/UpdateOwnInfo'

import Plans from './components/plans/Plans'
import Plan from './components/plans/Plan'
import AddPlan from './components/plans/AddPlan'
import UpdatePlan from './components/plans/UpdatePlan'

import Sessions from './components/sessions/Sessions'
import Session from './components/sessions/Session'
import AddSession from './components/sessions/AddSession'

import Licenses from './components/licenses/Licenses'
import SelectPlan from './components/plans/SelectPlan'
import UpdateLicenses from './components/licenses/UpdateLicenses'
import AddPhoto from './components/catalogs/AddPhoto'
import Category from './components/catalogs/Category'
import Canvas from './components/canvas/Canvas'
import QuestionsList from "./components/questions/QuestionsList";
import AddQuestions from "./components/questions/AddQuestions";
import UpdateQuestions from "./components/questions/UpdateQuestions";
import ReportList from "./components/report/ReportList";

function App() {
  return (
    <Router>
      <div className='App'>
        <AlertComponent />
        <NavBar />
        <div style={{ display: 'flex', height: '93vh', overflow: 'scroll initial' }}>
          <Sidebar />
          <div className='w-100'>
            <Routes>
              <Route exact path='/' element={<Login />} />
              <Route path='/home' element={
                <AuthenticatedRoute>
                  <Home />
                </AuthenticatedRoute>
              } />

              <Route path='/welcome' element={
                <AuthenticatedRoute>
                  <Home2 />
                </AuthenticatedRoute>
              } />

              <Route path='/usuarios' element={
                <AuthenticatedRoute>
                  <Users />
                </AuthenticatedRoute>
              } />
              <Route path='/usuarios/:id' element={
                <AuthenticatedRoute>
                  <User />
                </AuthenticatedRoute>
              } />
              <Route path='/usuario' element={
                <AuthenticatedRoute>
                  <AddUser />
                </AuthenticatedRoute>
              } />
              <Route path='/informacionPersonal' element={
                <AuthenticatedRoute>
                  <UpdateOwnInfo />
                </AuthenticatedRoute>
              } />

              <Route path='/fotografias' element={
                <AuthenticatedRoute>
                  <Photos />
                </AuthenticatedRoute>
              } />

              <Route path='/fotografias/agregar' element={
                <AuthenticatedRoute>
                  <AddPhoto />
                </AuthenticatedRoute>
              } />


              <Route path='/categorias' element={
                <AuthenticatedRoute>
                  <Category />
                </AuthenticatedRoute>
              } />

              <Route path='/planes' element={
                <AuthenticatedRoute>
                  <Plans />
                </AuthenticatedRoute>
              } />
              <Route path='/planes/:id' element={
                <AuthenticatedRoute>
                  <Plan />
                </AuthenticatedRoute>
              } />
              <Route path='/planes/:id/actualizar' element={
                <AuthenticatedRoute>
                  <UpdatePlan />
                </AuthenticatedRoute>
              } />
              <Route path='/plan' element={
                <AuthenticatedRoute>
                  <AddPlan />
                </AuthenticatedRoute>
              } />

              <Route path='/licencias' element={
                <AuthenticatedRoute>
                  <Licenses />
                </AuthenticatedRoute>
              } />
              <Route path='/licencias/:id' element={
                <AuthenticatedRoute>
                  <UpdateLicenses />
                </AuthenticatedRoute>
              } />

              {/* <Route path='/Canvas' element={
                <AuthenticatedRoute>
                  <Canvas />
                </AuthenticatedRoute>
              } /> */}

              {/* <Route path='/sesion/:id' element={
                <AuthenticatedRoute>
                  <Canvas />
                </AuthenticatedRoute>
              } /> */}

              <Route path='/sessions' element={
                <AuthenticatedRoute>
                  <Sessions />
                </AuthenticatedRoute>
              } />
              <Route path='/sessions/:id' element={
                <AuthenticatedRoute>
                  <Session />
                </AuthenticatedRoute>
              } />
              <Route path='/sessions/:id/actualizar' element={
                <AuthenticatedRoute>
                  <UpdatePlan />
                </AuthenticatedRoute>
              } />
              <Route path='/session' element={
                <AuthenticatedRoute>
                  <AddSession />
                </AuthenticatedRoute>
              } />

              <Route path='/preguntas' element={
                <AuthenticatedRoute>
                  <QuestionsList />
                </AuthenticatedRoute>
              } />

              <Route path='/preguntas/agregar' element={
                <AuthenticatedRoute>
                  <AddQuestions />
                </AuthenticatedRoute>
              } />

              <Route path='/preguntas/:id/actualizar' element={
                <AuthenticatedRoute>
                  <UpdateQuestions />
                </AuthenticatedRoute>
              } />


              <Route path='/reportes' element={
                <AuthenticatedRoute>
                  <ReportList />
                </AuthenticatedRoute>
              } />

              <Route path='/login' element={<Login />} />
              <Route path='/seleccionaPlan' element={<SelectPlan />} />
              <Route path='/sesion/:id' element={<Canvas />} />
              <Route path='/password/:userToken/restore' element={<RestorePassword />} />
              <Route exact path='/getPlan' element={<AddUserWithPlan />} />
              <Route exact path='/hostUser' element={<AddHostUser />} />
              <Route exact path='/hostUserCode' element={<AddHostUserCode />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  )
}
export default App
