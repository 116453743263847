import Modal from 'react-bootstrap/Modal'
import React from 'react'

export default function VideoTutorial({ showModalVideo, handleClose }) {

	return (
		<Modal show={showModalVideo} onHide={handleClose} size='lg'>
			<Modal.Header closeButton>
				<Modal.Title>Video</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	)
}
