import axios from 'axios'
import { customAlphabet } from 'nanoid'
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-alleanza-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`
const planServiceUrl = `${host}${process.env.REACT_APP_SUBS_SERVICE || '/plan'}`

const accessMap = {
  isAdmin: ['ADMIN'],
  isOrganizational: ['ANFITRION_ORG'],
  isIndividual: ['ANFITRION_PER'],
  isHost: ['ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  isGuest: ['INVITADO'],
  home: ['ADMIN', 'ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  perfil: ['ADMIN', 'ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  login: ['ADMIN', 'ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  membership: ['ANFITRION_ORG', 'ANFITRION_PER'],
  users: ['ADMIN'],
  plans: ['ADMIN'],
  canvas: ['ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  session: ['ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'],
  questions: ['ADMIN'],
}

// const planServiceUrl = 'http://localhost:3006/pjd-alleanza-subs'

const db = axios.create({
  baseURL: userServiceUrl,
})

const dbPlans = axios.create({
  baseURL: planServiceUrl,
})

export const currencyFormat = ({ number }) => {
  try {
    return '$' + number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } catch (error) {
    return '-'
  }
  
}

// Login Endpoint
export const login = async ({ path, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path) {
      const response = await db.post(`/${path}`, data, config)
      const userData = response?.data?.data || response.data

      if(Object.prototype.hasOwnProperty.call(userData, 'token')) {
        const expiry = new Date().getTime() + (4 * 1000 * 60 * 60)
        const data = {
            value: userData.token,
            userId: userData.userId,
            pendingPlan: userData.pendingPlan,
            sessionCode: userData.sessionCode,
            fullName: userData.fullName,
            expiry,
            permissions: userData?.permissions || [], 
        }
        localStorage.setItem('alleanza_jwtoken', JSON.stringify(data))
      }
      return userData

    } else {
      return false
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Validate User Permissions
export const validatePermissions = (permissions, component) => {
  if (accessMap[`${component}`]) {
    return (accessMap[`${component}`].find((permission) => permissions.includes(permission))) ? true : false
  }
  return false
}

export const getDomain = () => {
  var hostname = window.location.hostname.split('.')
  return hostname.slice(-2).join('.')
}

// Validate Token
export const validateToken = async (data) => {
  try {
    const response = await db.post(`/users/token`, data)
    return response.data.data
  } catch (error) {
    return { permissions: [] }
  }
}

// Get autogenerated/new password encrypted for new users
export const getEncryptedPassword = async (pass) => {
  let password = pass
  if (!password) {
    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTVUWXYZabcdefghijklmnopqrstuvwxyz_-!.', 8)
    password = nanoid()
  }

  const config = { 
    headers: {
      authorization: `Bearer ${password}`
    }
  }

  const response = await db.get(`/users/validate`, config)
  return {
    password,
    userPass: response.data.data.hash
  }
}

// Search/Get Records on the DB
export const search = async ({ path, keywords = '', pageSize = 25, page = 1, type = '', sortBy, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  
    const params = new URLSearchParams({
      keywords,
      pageSize,
      page,
      type,
      sortBy,
    })
  
    const response = await db.get(`/${path}?${params}`, config)
    return response.data
  } catch (error) {
    const response = {
      error: true,
      message: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    }
    return response
  }
}

// Get Record by Id
export const getById = async ({ path, id, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    if(path && id) {
      const response = await db.get(`/${path}/${id}`, config)
      return response?.data?.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    } else {
      return {}
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Add Record
export const add = async ({ path, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path) {
      const response = await db.post(`/${path}`, data, config)
      return response?.data?.data || response.data
    } else {
      return false
    }
  } catch (error) {
    const message = error?.response?.data?.error ||  error.message || error.error
    const response = message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Patch Record
export const patchById = async ({ path, id, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path && id) {
      let response = await db.patch(`/${path}/${id}`, data, config)
      return response?.data?.data || response.data
    } else {
      return true
    }
  } catch (error) {
    const response = error.response.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

///////////    PLANS API     ///////////
// Search/Get Records on the DB
export const searchPA = async ({ path, keywords = '', pageSize = 25, page = 1, sortBy, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  
    const params = new URLSearchParams({
      keywords,
      pageSize,
      page,
      sortBy
    })
  
    const response = await dbPlans.get(`/${path}?${params}`, config)
    return response.data
  } catch (error) {
    const response = {
      error: true,
      message: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    }
    return response
  }
}

// Get Records by Id
export const getByIdPA = async ({ path, id, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path && id) {
      const response = await dbPlans.get(`/${path}/${id}`, config)
      return response?.data?.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    } else {
      return {}
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Add Record
export const addPA = async ({ path, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path) {
      const response = await dbPlans.post(`/${path}`, data, config)
      return response?.data?.data || response.data
    } else {
      return false
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Patch Record
export const patchByIdPA = async ({ path, id, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path && id) {
      let response = await dbPlans.patch(`/${path}/${id}`, data, config)
      return response?.data?.data || response.data
    } else {
      return true
    }
  } catch (error) {
    const response = error.response.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Delete Record
export const deleteByIdPA = async ({ path, id, token, message = '¿Seguro que deseas eliminar este registro?' }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(window.confirm(message)) {
      await dbPlans.delete(`/${path}/${id}`, config)
      return {
        deleted: true,
        id
      }
    }
    return {
      deleted: false,
      id
    }
  }catch (error) {
    return {
      deleted: false,
      error
    }
  }
}

/////////// COMMON ENDPOINTS ///////////
// Get all options
export const getAll = async ({ path, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path) {
      const response = await db.get(`/${path}`, config)
      return response?.data?.data || response.data
    } else {
      return []
    }
  } catch (error) {
    // const response = error.response.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    // Temporal response to avoid failing
    const response = []
    return response
  }
}

// Update Option
export const updateById = async ({ path, id, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    } 

    if(path && id) {
      const params = new URLSearchParams(data)

      let response = await db.put(`/${path}/${id}?${params}`, data, config)
      return response || true
    } else {
      return true
    }
  } catch (error) {
    const response = error.response.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Delete Option
export const deleteById = async ({ path, id, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(window.confirm('¿Seguro que deseas eliminar esta opción del catálogo?')) {
      await db.delete(`/${path}/${id}`, config)
      return true
    }
    return false
  }catch (error) {
    return error
  }
}
/////////// COMMON ENDPOINTS ///////////

// Get logged user details
// export const getLoggedUser = async (userId, token) => {
//   const config = { 
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   }

//   const response = await db.get(`/users/${userId}`, config)
//   return response.data.data
// }

// Authenticate user
// export const authUser = async (data) => {
//   const response = await db.post(`/users/login`, data)
//   return response.data.data || response.data.message
// }

// Send url to restore password by email
export const sendRestoreUrl = async (userEmail) => {
  try {
    const data = {}
    const response = await db.patch(`/users/${userEmail}/restoreUrl`, data)
    return response.data.data || response.data.message
  } catch (error) {
    const response = error.response.data || 'Se produjo un error en el servidor'
    return response
  }
}

// Send restored password by email
export const sendPasswordRestored = async (userToken) => {
  try {
    const data = {}
    const response = await db.patch(`/users/${userToken}/restorePassword`, data)
    return response.data.data || response.data
  } catch (error) {
    return false
  }
}

// Send restored license email
export const sendUpdateLicense = async ({ userId, data, token }) => {
  try {
    const config = { 
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await db.patch(`/users/${userId}/updateLicense`, data, config)
    return response.data.data || response.data
  } catch (error) {
    return false
  }
}

// Get user details
// export const getUser = async (userId, token) => {
//   const config = { 
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   }

//   const response = await db.get(`/users/${userId}`, config)
//   return response.data.data
// }

// export const getTempPass = () => {
//   try {
//     const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTVUWXYZabcdefghijklmnopqrstuvwxyz_-!.', 8)
//     return nanoid()
//   } catch (error) {
//     return 'Cmk_916!'
//   }
// }



// Get sessions for reports
export const getSessions = async ({ path, keywords = '', pageSize = 25, page = 1, sortBy, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const params = new URLSearchParams({
      keywords,
      pageSize,
      page,
      sortBy
    })

    const response = await dbPlans.get(`/${path}?${params}`, config)
    return response.data
  } catch (error) {
    const response = {
      error: true,
      message: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    }
    return response
  }
}

// Get sessions DATA for reports
export const getReportSession = async ({ path, sessionCode, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await dbPlans.get(`/${path}/${sessionCode}`, config)
    return response.data
  } catch (error) {
    const response = {
      error: true,
      message: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    }
    return response
  }
}
