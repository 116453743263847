import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import React from 'react'

export default function ResponseCanvas({ showModalResponse, handleClose, handleSelectResponse, saveResponse, responses = [], imagesSelected, handleChangePhrase }) { 
  return (
    <Modal show={showModalResponse} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        {/* <Modal.Title>Respuestas</Modal.Title> */}
        <div className='row'>
          <div className='col-9'>
            Tu respuesta: selecciona una o más imágenes para responder la pregunta​.
          </div>
          <div className='col-3' style={{ 'text-align': 'right' }}>
            <button className='btn btn-primary btn-block mb-3' onClick={saveResponse}>
              Guardar Foto
            </button>
          </div>
        </div>
      </Modal.Header>
      
      <Modal.Body>
        <Form>
          <div className='row'>
            {responses.map((response, index) => (
              <div className={`card col-3`} key={index}>
                <div onClick={() => handleSelectResponse({ imageId: response.imageId, response })} className={`card col-4 w-100 text-bg-secondary ${imagesSelected.includes(response.imageId) ? 'border-naranja' : ''}  bg-transparent `} key={response.imageId} style={{ width: '8rem' }}>
                  <div className='card-body' style={{ 'verticalAlign': 'middle', 'text-align': 'center' }} >
                    <img
                      className='text-center'
                      src={response.image}
                      alt='Loading...'
                      width='150'
                    />
                  </div>
                </div>
                {imagesSelected.includes(response.imageId) && (
                  <div className='form-group mt-2 mb-1'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Agrega una frase'
                      defaultValue={response.phrase}
                      value={response.phrases}
                      onChange={(e) => handleChangePhrase(e, index, response.imageId)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <button className='btn btn-primary btn-block mb-3' onClick={saveResponse}>
          Guardar Foto
        </button>
      </Modal.Footer>
    </Modal>
  )
}
