import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { add, search, getEncryptedPassword, addPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

// eslint-disable-next-line
export default function (props) {
  const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
  const navigate = useNavigate()
  const { uses, industries, authenticateUser, authenticatedUser, setIndustries, setUses, setAlert, setToken } = useUserContext()

  const [isIndividual, setIndividual] = useState(true)
  const [isOrganizational, setOrganizational] = useState(false)
  const [userEmail, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [password, setPass] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [confirmPassword, setConfirmPass] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [useId, setUseId] = useState('')

  const [organization, setOrganization] = useState('')
  const [industryId, setIndustryId] = useState('')
  const [position, setPosition] = useState('')

  const queryParams = new URLSearchParams(window.location.search)
  const [planId, setPlanId] = useState(parseInt(queryParams.get('plan'), 10) || false)
  const [noLicenses, setNoLicenses] = useState(parseInt(queryParams.get('licenses'), 10) || 0)
  
  const handleChangeFormType = () => {
    setIndividual(!isIndividual)
    setOrganizational(!isOrganizational)
  }

  const handleChangeShowPass = (e) => {
    e.preventDefault()
    const type = showPass ? false : true
    setShowPass(type)
  }

  const handleChangeShowConfirmPass = (e) => {
    e.preventDefault()
    const type = showConfirmPass ? false : true
    setShowConfirmPass(type)
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if(e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }

  const handleChangePass = (e) => setPass(e.target.value)
  const handleChangeConfirmPass = (e) => setConfirmPass(e.target.value)
  const handleChangeFirstName = (e) => setFirstName(e.target.value)
  const handleChangeLastName = (e) => setLastName(e.target.value)
  const handleChangeCountry = (e) => setCountry(e.target.value)
  const handleChangeUseId = (e) => setUseId(e.target.value)

  const handleChangeOrganization = (e) => setOrganization(e.target.value)
  const handleChangeIndustryId = (e) => setIndustryId(e.target.value)
  const handleChangePosition = (e) => setPosition(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!validEmail) {
      setAlert({
        message: 'Por favor ingresa el correo electrónico con formato válido.',
        msgType: 'warning'
      })
    } else if(password !== confirmPassword) {
      setAlert({
        message: 'La contraseña es diferente en campo de confirmación',
        msgType: 'warning'
      })
    } else if(userEmail === '' || password === '' || firstName === '' || lastName === '' || country === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })
      const record = await getEncryptedPassword(password)

      let userData = await add({ path: 'users/registry',
      data: {
        userEmail: userEmail.trim(),
        userPass: record.userPass.trim(),
        firstName,
        lastName,
        country,
        useId: !isIndividual ? undefined : useId,
        organization: !isOrganizational ? undefined : organization,
        industryId: !isOrganizational ? undefined : industryId,
        position: !isOrganizational ? undefined : position,
        roles: isIndividual ? ['ANFITRION_PER'] : ['ANFITRION_ORG'],
      }, 
      token: '' })

      if(Object.prototype.hasOwnProperty.call(userData, 'userId')) {
        userData = {
          ...userData,
          value: userData.token,
          pendingPlan: false,
          sessionCode: false,
        }
        authenticateUser(userData)
        setToken(userData.token)
        if(planId) {
          const licensesNumber = noLicenses > 0 ? noLicenses : 3
          let planData = await addPA({ path: 'memberships',
          data: {
            userId: authenticateUser.userId,
            licensesNumber: parseInt(licensesNumber, 10),
            planId: parseInt(planId, 10),
          }, 
          token: userData.value })
    
          if(Object.prototype.hasOwnProperty.call(planData, 'planId')) {
            navigate('/sessions')
          } else {
            setAlert({
              message: planData.message,
              msgType: 'warning'
            })
          }
        } else {
          navigate('/seleccionaPlan')
        }
      } else {
        setAlert({
          message: userData.message || userData,
          msgType: 'warning'
        })
      }
    }
  }

  const fetchData = async () => {
    let response = []
    response = await search({ path: 'uses', type: 'independiente', token: '' })
    if (response.error) {
      setUses([])
    } else {
      setUses(response.data)
    }

    response = await search({ path: 'industries', token: '' })
    if (response.error) {
      setIndustries([])
    } else {
      setIndustries(response.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Registro de Usuario</h2>
            </div>

            <div className='form-group mt-3 text-center'>
              <div className='form-check form-check-inline'>
                <input className='form-check-input' id='inlineRadio1' type='radio' name='inlineRadioOptions' checked={isIndividual} onChange={handleChangeFormType} />
                <label className='form-check-label mx-2' htmlFor='inlineRadio1'>Licencia Independiente </label>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                  <title>Voy a adquirir una licencia para uso personal</title>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                </svg>
              </div>
              {/* <div className='form-check form-check-inline'>
                <input className='form-check-input' id='inlineRadio2' type='radio' name='inlineRadioOptions' checked={isOrganizational} onChange={handleChangeFormType} />
                <label className='form-check-label mx-2' htmlFor='inlineRadio2'>Licencia Organizacional </label>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                <title>Voy a adquirir una licencia para uso profesional</title>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                </svg>
              </div> */}
            </div> 
            
            {/* <h3 className='Auth-form-title'>Sign In</h3> */}

            <div className='row'>
              <div className='form-group mt-3 col-6'>
                <label>Email usuario: *</label>
                <input
                  type='email'
                  className='form-control mt-1'
                  placeholder='Email usuario'
                  value={userEmail}
                  onChange={handleChangeEmail}
                />
              </div>
              {/* {!validEmail && (
                <label className='mt-2 text-lg text-danger'>Correo con formato inválido</label>
              )} */}

              <div className='form-group mt-3 col-6'>
                <label>País de residencia: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='País de residencia'
                  value={country}
                  onChange={handleChangeCountry}
                />
              </div>
            </div>

            <div className='row'>
              {!validEmail && (
                <label className='mt-2 text-lg text-danger col-6'>Correo con formato inválido</label>
              )}
            </div>

            <div className='row'>
              <div className='form-group mt-3 col-6'>
                <label>Contraseña: *</label>
                <input
                  type={showPass ? 'text' : 'password'}
                  className='form-control mt-1'
                  placeholder='Contraseña'
                  value={password}
                  onChange={handleChangePass}
                />
                {password && (
                  <button className='btn btn btn-light btn-block btn-outline-dark eye-icon' onClick={handleChangeShowPass}>
                    {showPass ? (<FaEyeSlash />) : (<FaEye />)}
                  </button>
                )}
              </div>

              <div className='form-group mt-3 col-6'>
                <label>Confirmación Contraseña: *</label>
                <input
                  type={showConfirmPass ? 'text' : 'password'}
                  className='form-control mt-1'
                  placeholder='Confirmación Contraseña'
                  value={confirmPassword}
                  onChange={handleChangeConfirmPass}
                />
                {confirmPassword && (
                  <button className='btn btn btn-light btn-block btn-outline-dark eye-icon' onClick={handleChangeShowConfirmPass}>
                    {showConfirmPass ? (<FaEyeSlash />) : (<FaEye />)}
                  </button>
                )}
              </div>
            </div>

            {/* LICENCIA INDEPENDIENTE */}
            {isIndividual && (<>
              <div className='row'>
                <div className='form-group mt-3 col-6'>
                  <label>Nombre: *</label>
                  <input
                    type='text'
                    className='form-control mt-1'
                    placeholder='Nombre'
                    value={firstName}
                    onChange={handleChangeFirstName}
                  />
                </div>

                <div className='form-group mt-3 col-6'>
                  <label>Apellidos: *</label>
                  <input
                    type='text'
                    className='form-control mt-1'
                    placeholder='Apellidos'
                    value={lastName}
                    onChange={handleChangeLastName}
                  />
                </div>
              </div>
              {/* <div className='form-group mt-3'>
                <label>Uso principal: *</label>
                <select 
                  className='form-select mt-1'
                  data-choices='data-choices'
                  data-options='{"removeItemButton":true,"placeholder":true}'
                  value={useId}
                  onChange={handleChangeUseId}>
                    <option disabled defaultValue key='' value=''>Selecciona una opción</option>
                    {uses.map((options) => (
                      <option key={options.id} value={options.id}>
                        {options.name}
                      </option>
                    ))}
                </select>
              </div> */}
            </>)}
            {/* LICENCIA INDEPENDIENTE */}

            {/* LICENCIA ORGANIZACIONAL */}
            {isOrganizational && (<>
              <div className='form-group mt-3'>
                <label>Nombre de la Organización: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='Nombre de la Organización'
                  value={organization}
                  onChange={handleChangeOrganization}
                />
              </div>

              <div className='form-group mt-3'>
                <label>Industria: *</label>
                <select 
                  className='form-select mt-1'
                  data-choices='data-choices'
                  data-options='{"removeItemButton":true,"placeholder":true}'
                  value={industryId}
                  onChange={handleChangeIndustryId}>
                    <option disabled defaultValue key='' value=''>Selecciona una opción</option>
                    {industries.map((options) => (
                      <option key={options.id} value={options.id}>
                        {options.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className='form-group mt-4'>
                <h6>Persona de contacto</h6>
              </div>

              <div className='form-group mt-3'>
                <label>Nombre: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='Nombre'
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </div>

              <div className='form-group mt-3'>
                <label>Apellidos: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='Apellidos'
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </div>

              <div className='form-group mt-3'>
                <label>Cargo: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='Cargo'
                  value={position}
                  onChange={handleChangePosition}
                />
              </div>

              <div className='form-group mt-3'>
                <label>País: *</label>
                <input
                  type='text'
                  className='form-control mt-1'
                  placeholder='País'
                  value={country}
                  onChange={handleChangeCountry}
                />
              </div>
            </>)}
            {/* LICENCIA ORGANIZACIONAL */}

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
