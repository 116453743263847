import React from 'react'
import Logo from '../components/layout/assets/logo.png'

// eslint-disable-next-line
export default function (props) {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  return (
    <>
      <div className='text-center hero-content text-3xl card-title pt-0 pb-0'>
        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
          <img
            className='text-center mx-auto'
            src={Logo}
            alt='Cargando...'
          />
        </div>
      </div>

      <div className='text-center hero-content'>
        <h3 className='card-title'>Bienvenido! Te registraste con éxito.​</h3>
        <h3 className='card-title'>Te enviamos un correo para que pruebes tu primera sesión.</h3>
      </div>
    </>
  )
}
